import http from "../../utils/http"
/**
 * 获取我的销售
 */
export function getSellers(params) {
    return http({
        url: "/api/member/mySeller",
        data: params,
        forceLogin: true
    })
}