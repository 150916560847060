<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>

        <div class="my-point" v-loading="loading">
            <div class="member-point">
                <div class="title">我的销售</div>
            </div>
            <div class="detail">
                <el-tabs type="border-card">
                    <el-tab-pane label="我联系的">
                        <div class="list" v-for="(seller, index) in iContacts.list" :key="index">
                            <div><span>{{ seller.name }}</span> <span>{{ seller.phone }}</span></div>
                        </div>
                        <el-pagination small layout="prev, pager, next" :total="iContacts.total" @current-change="loadIContacts">
                        </el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="联系我的">
                        <div class="list" v-for="(seller, index) in contactMes.list" :key="index">
                            <div><span>{{ seller.name }}</span> <span>{{ seller.phone }}</span></div>
                        </div>
                        <el-pagination small layout="prev, pager, next" :total="contactMes.total" @current-change="loadContactMes">
                        </el-pagination>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { getSellers } from  '@/api/member/my_sellers'
export default {
    name: "my_sellers",
    components: {},
    data: () => {
        return {
            contactMes: {
                list: [],
                page: 1,
                total: 10,
            },
            iContacts: {
                list: [],
                page: 1,
                total: 10,
            },
            loading: true,
            yes: true
        }
    },
    created() {
        this.loading = false
        this.loadIContacts(this.iContacts.page)
        this.loadContactMes(this.contactMes.page)
    },
    mounted() {
        let self = this;
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        loadIContacts(page) {
            this.iContacts.page = page
            getSellers({
                page: this.iContacts.page
            }).then((res) => {
                this.iContacts.list = res.data
                console.log("loadIContacts", res)
            })
        },
        loadContactMes(page) {
            this.contactMes.page = page
            getSellers({
                page: this.contactMes.page
            }).then((res) => {
                this.contactMes.list = res.data
                console.log("loadIContacts", res)
            })
            console.log("loadContactMes", page)
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.my-point {
    background: #ffffff;
    padding: 20px;

    .member-point {
        font-size: $ns-font-size-base;
        font-weight: 600;
        margin-bottom: 10px;

        .num {
            font-size: 30px;
            font-weight: 600;
        }
    }

    .page {
        display: flex;
        justify-content: center;
        align-content: center;
        padding-top: 20px;
    }
}
</style>
